define("discourse/plugins/custom-profile/discourse/components/json-links", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.parsedLinks.length}}
    {{#each this.parsedLinks as |link index|}}
      {{#if index}}
        &bull;
      {{/if}}
      <a href={{link.url}} target="_blank">{{link.title}}</a>
    {{/each}}
  {{/if}}
  */
  {
    "id": "TqIkKaBm",
    "block": "[[[41,[30,0,[\"parsedLinks\",\"length\"]],[[[42,[28,[37,2],[[28,[37,2],[[30,0,[\"parsedLinks\"]]],null]],null],null,[[[41,[30,2],[[[1,\"      •\\n\"]],[]],null],[1,\"    \"],[10,3],[15,6,[30,1,[\"url\"]]],[14,\"target\",\"_blank\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\"]],[1,2]],null]],[]],null]],[\"link\",\"index\"],false,[\"if\",\"each\",\"-track-array\",\"a\"]]",
    "moduleName": "discourse/plugins/custom-profile/discourse/components/json-links.hbs",
    "isStrictMode": false
  });
  class JsonLinks extends _component2.default {
    get parsedLinks() {
      try {
        const parsed = JSON.parse(this.args.jsonString);
        return parsed;
      } catch (e) {
        return [];
      }
    }
  }
  _exports.default = JsonLinks;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, JsonLinks);
});