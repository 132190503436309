define("discourse/plugins/custom-profile/discourse/templates/connectors/user-custom-controls/custom-profile", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group" data-setting-name="user-profile-edit">
    <label class="control-label">Your profile</label>
    <div>
      <a href="https://artistpigments.org/identity/profile/edit">
      <button class="btn btn-text btn-default" type="button">
        <span class="d-button-label">Edit your profile</span>
      </button>
      </a>
    </div>
    <div class="instructions">
        You can edit your profile here.
    </div>
  </div>
  
  */
  {
    "id": "C53Myvr7",
    "block": "[[[10,0],[14,0,\"control-group\"],[14,\"data-setting-name\",\"user-profile-edit\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"Your profile\"],[13],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,3],[14,6,\"https://artistpigments.org/identity/profile/edit\"],[12],[1,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-text btn-default\"],[14,4,\"button\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"d-button-label\"],[12],[1,\"Edit your profile\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"instructions\"],[12],[1,\"\\n      You can edit your profile here.\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"div\",\"label\",\"a\",\"button\",\"span\"]]",
    "moduleName": "discourse/plugins/custom-profile/discourse/templates/connectors/user-custom-controls/custom-profile.hbs",
    "isStrictMode": false
  });
});