define("discourse/plugins/custom-profile/discourse/templates/connectors/about-after-description/custom-profile", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="about-see-also"><p>See also topics in <a href="https://discussions.artistpigments.org/c/about/4">About</a></p></div>
  */
  {
    "id": "zXuwNvnd",
    "block": "[[[10,0],[14,0,\"about-see-also\"],[12],[10,2],[12],[1,\"See also topics in \"],[10,3],[14,6,\"https://discussions.artistpigments.org/c/about/4\"],[12],[1,\"About\"],[13],[13],[13]],[],false,[\"div\",\"p\",\"a\"]]",
    "moduleName": "discourse/plugins/custom-profile/discourse/templates/connectors/about-after-description/custom-profile.hbs",
    "isStrictMode": false
  });
});