define("discourse/plugins/custom-profile/discourse/templates/connectors/about-after-admins/custom-profile", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="about-impressum">
    <h3>Impressum</h3>
  
    <h4 class="about-impressum-tmg"><a name="p-134-information-according-to-5-tmg-2" class="anchor" href="#p-134-information-according-to-5-tmg-2"></a>Information according to § 5 TMG</h4>
    <p class="about-impressum-tmg">Tamas Majoros<br>
    Mittenwalder Str. 4<br>
    82057, Icking<br>
    Germany</p>
    <h4 class="about-impressum-contact"><a name="p-134-contact-3" class="anchor" href="#p-134-contact-3"></a>Contact</h4>
    <p class="about-impressum-contact">Email: <a href="mailto:info@artistpigments.org">info@artistpigments.org</a></p>
  </div>
  */
  {
    "id": "rCJMpYvZ",
    "block": "[[[10,0],[14,0,\"about-impressum\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,\"Impressum\"],[13],[1,\"\\n\\n  \"],[10,\"h4\"],[14,0,\"about-impressum-tmg\"],[12],[10,3],[14,3,\"p-134-information-according-to-5-tmg-2\"],[14,0,\"anchor\"],[14,6,\"#p-134-information-according-to-5-tmg-2\"],[12],[13],[1,\"Information according to § 5 TMG\"],[13],[1,\"\\n  \"],[10,2],[14,0,\"about-impressum-tmg\"],[12],[1,\"Tamas Majoros\"],[10,\"br\"],[12],[13],[1,\"\\n  Mittenwalder Str. 4\"],[10,\"br\"],[12],[13],[1,\"\\n  82057, Icking\"],[10,\"br\"],[12],[13],[1,\"\\n  Germany\"],[13],[1,\"\\n  \"],[10,\"h4\"],[14,0,\"about-impressum-contact\"],[12],[10,3],[14,3,\"p-134-contact-3\"],[14,0,\"anchor\"],[14,6,\"#p-134-contact-3\"],[12],[13],[1,\"Contact\"],[13],[1,\"\\n  \"],[10,2],[14,0,\"about-impressum-contact\"],[12],[1,\"Email: \"],[10,3],[14,6,\"mailto:info@artistpigments.org\"],[12],[1,\"info@artistpigments.org\"],[13],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"h3\",\"h4\",\"a\",\"p\",\"br\"]]",
    "moduleName": "discourse/plugins/custom-profile/discourse/templates/connectors/about-after-admins/custom-profile.hbs",
    "isStrictMode": false
  });
});