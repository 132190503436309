define("discourse/plugins/custom-profile/discourse/components/json-array", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.parsed.length}}
    {{#each this.parsed as |item index|}}
      {{#if index}}
        &bull;
      {{/if}}
      {{item}}
    {{/each}}
  {{/if}}
  */
  {
    "id": "mHmgVo9+",
    "block": "[[[41,[30,0,[\"parsed\",\"length\"]],[[[42,[28,[37,2],[[28,[37,2],[[30,0,[\"parsed\"]]],null]],null],null,[[[41,[30,2],[[[1,\"      •\\n\"]],[]],null],[1,\"    \"],[1,[30,1]],[1,\"\\n\"]],[1,2]],null]],[]],null]],[\"item\",\"index\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "discourse/plugins/custom-profile/discourse/components/json-array.hbs",
    "isStrictMode": false
  });
  class JsonArray extends _component2.default {
    get parsed() {
      try {
        const parsed = JSON.parse(this.args.jsonString);
        return parsed;
      } catch (e) {
        return [];
      }
    }
  }
  _exports.default = JsonArray;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, JsonArray);
});