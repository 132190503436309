define("discourse/plugins/custom-profile/discourse/templates/connectors/user-card-metadata/custom-profile", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <style>
    .metadata__user-created,
    .metadata__last-posted { display: none; }
  </style>
  <div style="display: flex; justify-content: space-between; align-items: flex-start;">
    <div class="custom-metadata__user-created">
      <span class="desc">{{i18n "joined"}}</span>
      {{format-date this.user.created_at leaveAgo="true"}}
    </div>
  
    {{#if this.user.last_seen_at}}
      <div class="custom-metadata__last-seen" style="margin-left: 0.5em;">
        <span class="desc">{{i18n "user.last_seen"}}</span>
        {{#if (this.isToday this.user.last_seen_at)}}
          today
        {{else}}
          {{format-membership-date this.user.last_seen_at}} ago
        {{/if}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "jGM13Xqe",
    "block": "[[[10,\"style\"],[12],[1,\"\\n  .metadata__user-created,\\n  .metadata__last-posted { display: none; }\\n\"],[13],[1,\"\\n\"],[10,0],[14,5,\"display: flex; justify-content: space-between; align-items: flex-start;\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"custom-metadata__user-created\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"desc\"],[12],[1,[28,[35,3],[\"joined\"],null]],[13],[1,\"\\n    \"],[1,[28,[35,4],[[30,0,[\"user\",\"created_at\"]]],[[\"leaveAgo\"],[\"true\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"user\",\"last_seen_at\"]],[[[1,\"    \"],[10,0],[14,0,\"custom-metadata__last-seen\"],[14,5,\"margin-left: 0.5em;\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"desc\"],[12],[1,[28,[35,3],[\"user.last_seen\"],null]],[13],[1,\"\\n\"],[41,[28,[30,0,[\"isToday\"]],[[30,0,[\"user\",\"last_seen_at\"]]],null],[[[1,\"        today\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,6],[[30,0,[\"user\",\"last_seen_at\"]]],null]],[1,\" ago\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[13]],[],false,[\"style\",\"div\",\"span\",\"i18n\",\"format-date\",\"if\",\"format-membership-date\"]]",
    "moduleName": "discourse/plugins/custom-profile/discourse/templates/connectors/user-card-metadata/custom-profile.hbs",
    "isStrictMode": false
  });
});