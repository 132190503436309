define("discourse/plugins/custom-profile/discourse/connectors/user-card-metadata/custom-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(attrs, component) {
      component.reopen({
        isToday(date) {
          if (!date) {
            return false;
          }
          const today = new Date();
          const checkDate = new Date(date);
          return today.toDateString() === checkDate.toDateString();
        }
      });
    }
  };
});