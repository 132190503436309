define("discourse/plugins/custom-profile/discourse/helpers/format-membership-date", ["exports", "@ember/component/helper", "discourse/lib/formatter"], function (_exports, _helper, _formatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // discourse/plugins/custom-profile/assets/javascripts/discourse/helpers/format-membership-date.js
  var _default = _exports.default = (0, _helper.helper)(function formatMembershipDate(_ref) {
    let [dateString] = _ref;
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    const distance = (Date.now() - date.getTime()) / 1000;
    return (0, _formatter.durationTiny)(distance, {
      format: 'medium'
    });
  });
});