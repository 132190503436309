define("discourse/plugins/custom-profile/discourse/templates/connectors/user-card-post-names/custom-profile", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
    {{#if this.user.user_fields.[1]}}
      <JsonArray @jsonString={{this.user.user_fields.[1]}} />
    {{/if}}
  </div>
  
  */
  {
    "id": "nPPQ8fhD",
    "block": "[[[10,0],[12],[1,\"\\n\"],[41,[30,0,[\"user\",\"user_fields\",\"1\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@jsonString\"],[[30,0,[\"user\",\"user_fields\",\"1\"]]]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[],false,[\"div\",\"if\",\"json-array\"]]",
    "moduleName": "discourse/plugins/custom-profile/discourse/templates/connectors/user-card-post-names/custom-profile.hbs",
    "isStrictMode": false
  });
});