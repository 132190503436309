define("discourse/plugins/custom-profile/discourse/components/json-badges", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.parsed.length}}
    <div class="badge-container">
      {{#each this.visibleItems as |item|}}
        <span style="
          display: inline-block;
          padding: 2px 4px;
          margin: 2px 4px;
          font-size: 0.85em;
          background-color: #fafaf9;
          color: #333;
          border-radius: 2px;
          white-space: nowrap;">
          {{item}}
        </span>
      {{/each}}
  
      {{#if (gt this.parsed.length this.maxVisible)}}
        {{#if this.showAll}}
          <span
            style="
              display: inline-block;
              padding: 2px 8px;
              margin: 2px 4px;
              font-size: 0.85em;
              background-color: #e0e0e0;
              color: #666;
              border-radius: 2px;
              cursor: pointer;"
            {{on "click" this.toggleShowAll}}>
            - show less
          </span>
        {{else}}
          <span
            style="
              display: inline-block;
              padding: 2px 8px;
              margin: 2px 4px;
              font-size: 0.85em;
              background-color: #e0e0e0;
              color: #666;
              border-radius: 2px;
              cursor: pointer;"
            {{on "click" this.toggleShowAll}}>
            +{{this.remainingCount}} more...
          </span>
        {{/if}}
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "2je413md",
    "block": "[[[41,[30,0,[\"parsed\",\"length\"]],[[[1,\"  \"],[10,0],[14,0,\"badge-container\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"visibleItems\"]]],null]],null],null,[[[1,\"      \"],[10,1],[14,5,\"\\n        display: inline-block;\\n        padding: 2px 4px;\\n        margin: 2px 4px;\\n        font-size: 0.85em;\\n        background-color: #fafaf9;\\n        color: #333;\\n        border-radius: 2px;\\n        white-space: nowrap;\"],[12],[1,\"\\n        \"],[1,[30,1]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[41,[28,[37,5],[[30,0,[\"parsed\",\"length\"]],[30,0,[\"maxVisible\"]]],null],[[[41,[30,0,[\"showAll\"]],[[[1,\"        \"],[11,1],[24,5,\"\\n            display: inline-block;\\n            padding: 2px 8px;\\n            margin: 2px 4px;\\n            font-size: 0.85em;\\n            background-color: #e0e0e0;\\n            color: #666;\\n            border-radius: 2px;\\n            cursor: pointer;\"],[4,[38,6],[\"click\",[30,0,[\"toggleShowAll\"]]],null],[12],[1,\"\\n          - show less\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[11,1],[24,5,\"\\n            display: inline-block;\\n            padding: 2px 8px;\\n            margin: 2px 4px;\\n            font-size: 0.85em;\\n            background-color: #e0e0e0;\\n            color: #666;\\n            border-radius: 2px;\\n            cursor: pointer;\"],[4,[38,6],[\"click\",[30,0,[\"toggleShowAll\"]]],null],[12],[1,\"\\n          +\"],[1,[30,0,[\"remainingCount\"]]],[1,\" more...\\n        \"],[13],[1,\"\\n\"]],[]]]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"item\"],false,[\"if\",\"div\",\"each\",\"-track-array\",\"span\",\"gt\",\"on\"]]",
    "moduleName": "discourse/plugins/custom-profile/discourse/components/json-badges.hbs",
    "isStrictMode": false
  });
  class JsonBadges extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "showAll", [_tracking.tracked], function () {
      return false;
    }))();
    #showAll = (() => (dt7948.i(this, "showAll"), void 0))();
    maxVisible = (() => this.args.maxVisible || 5)();
    get parsed() {
      try {
        const parsed = JSON.parse(this.args.jsonString);
        return parsed;
      } catch (e) {
        return [];
      }
    }
    get visibleItems() {
      if (this.showAll) {
        return this.parsed;
      }
      return this.parsed.slice(0, this.maxVisible);
    }
    get remainingCount() {
      return this.parsed.length - this.maxVisible;
    }
    get hasMore() {
      return this.parsed.length > this.maxVisible;
    }
    toggleShowAll() {
      this.showAll = !this.showAll;
    }
    static #_2 = (() => dt7948.n(this.prototype, "toggleShowAll", [_object.action]))();
  }
  _exports.default = JsonBadges;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, JsonBadges);
});